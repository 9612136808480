<template>
    <div class="flag-container">
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <el-col class="flag-list" :class="{'limit':m.can_mix_into==0}" v-for="(m, index) in list">
                <a href="javascript:;">
                    <el-skeleton :loading="loading" animated :count="1">
                        <template slot="template">
                            <el-skeleton-item variant="image" class="img" />
                            <div class="content">
                                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                <el-skeleton-item variant="text" style="width: 50%;" />
                            </div>
                        </template>
                        <template>
                            <div class="flag-box">
                                <div class="img-box">
                                    <el-image class="img" :class="{ 'video-img': m.video }" :src="getImg(m)" fit="cover">
                                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                    </el-image>
                                </div>
                                <div class="content">
                                    <div class="title-box">
                                        <div class="title ellipsis-2">{{m.title || clearHtml(m.content) }}</div>
                                        <span class="add-btn" v-show="m.can_mix_into==1" @click="addMix(m.dy_id,index)">{{mix[m.dy_id]?'已添加':'添加'}}</span>
                                    </div>
                                    <span class="tag" v-if="m.shenhe==-1 && m.reason">原因：{{m.reason}}</span>
                                    <div class="bottom">
                                        <span class="reason" v-if="m.can_mix_into_reason">{{m.can_mix_into_reason}}</span>
                                        <span class="time" v-if="m.datetime">发布于{{m.datetime}}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </a>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        name: 'mixList',
        components: {},
        props: ['list', 'list2'],
        data() {
            return {
                loading: false,
                mix: {}
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。

        },
        mounted() {
            // 在组件被挂载之后调用。
            let obj = {};
            this.list2.forEach(v => {
                obj[v.id] = v;
            });
            this.mix = obj

        },
        methods: {
            addMix(id, i) {
                if (this.mix[id]) return;
                this.$set(this.mix, id, 1)
                this.$emit('addMix', {
                    id: id,
                    i: i
                });
            },
            // 获取图片
            getImg(m) {
                let img = m.video ? m.cover : m.masterimg[0];
                return this.rImg(img);
            },
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';
    @import '@assets/less/mylist.less';

    .flag-list {
        &.limit {
            .img-box {
                opacity: 0.3;
            }

            .title-box {
                opacity: 0.3;

            }
        }

        .video-img::after {
            font-size: 20px;
        }

        .img {
            width: 100px;
            height: 70px;
            border-radius: 7px;
        }

        .content {
            .title {
                font-size: 14px;
            }

            .add-btn {
                color: #49C265;
            }
        }

        .bottom {
            .reason {
                background: #aaa;
                color: #fff;
                font-size: 12px;
                margin-right: 10px;
                padding: 3px 5px;
            }

        }
    }
    @media (max-width: 600px){
        .time{
            display: none;
        }
    }
</style>